import {React, useState, useEffect} from "react";
import "../Styles/homepage.css";
import navigate from "./funcs";
import { useDispatch, useSelector } from "react-redux";
import { searchQuery, saveQuery } from "../state/resultsSlice";
import { updateResults } from '../state/resultsSlice';

export default function TargetDocument(){
    const dispatch = useDispatch()
    const {targetDocument} = useSelector(state=> state.results)
    return(
        <div dangerouslySetInnerHTML={{__html: targetDocument.body}}></div>
    )
}

const styles= {
};