import {React, useState, useEffect} from "react";
import "../Styles/homepage.css";
import navigate from "./funcs";
import { useDispatch } from "react-redux";
import { searchQuery, saveQuery } from "../state/resultsSlice";
import { updateResults, updateTargetDocument , imagesQuery, updateImages} from '../state/resultsSlice';



export default function HomePage(){
    const dispatch = useDispatch()
    const [searchQ, setSearchQ] = useState("")
    
    useEffect(()=> {search()}, [searchQ])
    const search = () => {
        dispatch(searchQuery(searchQ)).then( result => dispatch(updateResults({ results: result })))
        dispatch(imagesQuery(searchQ)).then(result => dispatch(updateImages({images: result})))
        dispatch(saveQuery({query: searchQ}))
    }
    const generateRandomQuery =  () => {
        const randomQueries = ["Berlin", 
        "Paris", 
        "Space",
        "The Great Barrier Reef",
        ]
        //choose random query from array
        const randomQuery = randomQueries[Math.floor(Math.random() * randomQueries.length)];
        setSearchQ(randomQuery)
        
    }
    
    return(
        <>
            <nav><button style={styles.addButton} onClick={() => navigate('add-doc')}> + </button></nav>
            <div className="container">
                <img style={styles.gif} src="https://acegif.com/wp-content/uploads/2022/4hv9xm/dancing-duck-acegifcom-70.gif"/>
                <h1>Duck Duck Oh</h1>
                <input value={searchQ} onChange={(e)=> setSearchQ(e.target.value)} type="text" name="searchQuery"></input>
                <div className="row">
                    <button type="submit" onClick={() => {
                        search()
                        navigate('results')
                    }}>Search</button>
                    <button type="submit" onClick={()=> {
                        generateRandomQuery()
                        navigate('results')

                    }}>Search randomly</button>
                </div>
            </div>
        </>
        
    )
}

const styles= {
    gif:{
        width:"200px",
    },
    addButton:{
        //make circular
        marginTop:"10px",
        display:"flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius:"50%",
        width:"50px",
        height:"50px",
        fontWeight: "bold",
        fontSize: "25px"
    }
  };