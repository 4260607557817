import {React, useState} from "react";
import { useDispatch } from "react-redux";
import { addDocument } from "../state/resultsSlice";
import duckLogo from '../assets/duck-logo.png'
import navigate from "./funcs";

export default function AddNewDocument(){
    // Use the useState hook to store the document file and its file name
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [uploaded, setUploaded] = useState(false);
    const dispatch = useDispatch()
  // Handle changes to the file input
  const handleFileChange = (event) => {
    // Update the file and file name state variables
    setFile(event.target.files[0]);
    setFileName(event.target.files[0].name);
  };

  // Handle the form submission
  const handleSubmit = (event) => {
    setUploaded(false);
    event.preventDefault();
    //parse the html file to a string
    const reader = new FileReader();
    reader.onload = (event) => {
        const text = event.target.result;
        //remove new lines
        const textWithoutNewLines = text.replace(/(\r\n|\n|\r)/gm, "");
        dispatch(addDocument({body: textWithoutNewLines})).then(setUploaded(true));
    }
    reader.readAsText(file);
    
  };

  return (
    <>
        <h2 style={styles.back} onClick={() => navigate('/')}> Home </h2>
        <div style={styles.body}>
            <img style={styles.logo} src={duckLogo} onClick={() => navigate('/')}/>
            <form style={styles.form} onSubmit={handleSubmit}>
                <label style={styles.label} htmlFor="file-input">
                    Choose a file:
                </label>
                <button style={{
                    backgroundColor: "#f0f0f0",
                    color: "black"
                }}>
                    <label style={{cursor:"pointer"}} htmlFor="file-input">
                        Select a document
                        <input
                            style={styles.input}
                            type="file"
                            id="file-input"
                            onChange={handleFileChange}
                            accept=".html"
                            />
                    </label>
                    </button>
                <br />
                {fileName ? (
                    <p>Selected file: {fileName}</p>
                ) : (
                    <p>No file selected</p>
                )}
                <br />
                <button  type="submit">Add document</button>
                {uploaded ? ( <p style={styles.orange}>Document added!</p>) : (null)}
            </form>
        </div>
    </>
    
  );
}

const styles= {
    logo:{
        width: "100px",
        cursor: "pointer",
    },
    body:{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    form:{
        fontSize: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
   input:{
        display: "none"
    },
    back:{
        position:"fixed",
        marginLeft: "20px",
        cursor: "pointer",
    },
    orange:{
        color: "orange",
    }
};