import React from "react";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "./searchApi";
const initialState = {
    results: [],
    query: "",
    targetDocument: {},
    images: []
};

export const searchQuery = createAsyncThunk(
    "serachQuery/results",
    async (query) => {
        const response = await api.get(`/search?query=${query}`)
        
        return response.data;
    }
);
export const imagesQuery = createAsyncThunk(
    "imagesQuery/results",
    async (query) => {
        const response = await api.get(`/search/images?query=${query}`)
        return response.data;
    }
);
export const addDocument = createAsyncThunk(
    "addDocument/documents",
    async (document) => {
        const temp = JSON.stringify(document)
        const response = await api.post('/documents', JSON.parse(temp))
        console.log("docs", response)
        return response.data;
    }
);

const resultsSlice = createSlice({
    name: "results",
    initialState,
    reducers: {
        updateResults: (state, action) => {
            state.results = action.payload.results.payload.results
            console.log("docs",state.results)
        },
        updateImages: (state, action) => {
            state.images = action.payload.images.payload.results
            console.log("img",state.images)
        },
        saveQuery: (state, action) => {
            state.query = action.payload.query

        },
        updateTargetDocument: (state, action) => {
            state.targetDocument = action.payload.targetDocument
            console.log(state.targetDocument)
        },
        
    }
});

export default resultsSlice.reducer;
export const { updateResults , saveQuery, updateTargetDocument, updateImages} = resultsSlice.actions