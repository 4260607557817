import logo from './logo.svg';
import { store, persistor } from './state/store'
import './App.css';
import {BrowserRouter, Routes ,Route} from 'react-router-dom';
import ResultPage from './Screens/results';
import HomePage from './Screens/homepage';
import AddNewDocument from './Screens/addNewDocument';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import TargetDocument from './Screens/targetDocument';
import { useEffect } from 'react';
function App() {
  useEffect(() => {localStorage.clear()}, [])
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage/>}/>
          <Route path='/results' element={<ResultPage/>}/>
          <Route path='/add-doc' element={<AddNewDocument/>}/>
          <Route path='/target-doc' element={<TargetDocument/>}/>
        </Routes>
      </BrowserRouter>
    </PersistGate>
    </Provider>
  );
}

export default App;
