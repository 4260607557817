import {React ,useState}from "react";
import { useSelector, useDispatch } from "react-redux";
import "../Styles/results.css";
import duckLogo from "../assets/duck-logo.png";
import { searchQuery, saveQuery } from "../state/resultsSlice";
import { updateResults, updateTargetDocument , imagesQuery, updateImages} from '../state/resultsSlice';
import navigate from './funcs'
export default function ResultPage(){
  const dispatch = useDispatch()
  const {query, results, images} = useSelector(state=> state.results)
  const [searchQ, setSearchQ] = useState(query)
  const [currentTab, setCurrentTab] = useState("documents")
  const search = () => {
    dispatch(searchQuery(searchQ)).then( result => dispatch(updateResults({ results: result })))
    dispatch(imagesQuery(searchQ)).then(result => dispatch(updateImages({images: result})))
    dispatch(saveQuery({query: searchQ}))
  }
  let documentNumber = 0
  const renderedResults = results.map((result)=> {
    documentNumber++
    return(
      <div key={documentNumber} >
        <h1 style={styles.title} onClick={()=> goToTargetDocument(result)}>{result.title}</h1>
        <span style={styles.body}>{result.outline}</span>
      </div>

    )
  })
  const renderedImages = images.map((image)=> {
    return(
      <img src={image.url} style={{width: "18vw", borderRadius: 5, objectFit:"cover"}}/>
    )
  })
  const goToTargetDocument = (result)=> {
    dispatch(updateTargetDocument({targetDocument: result}))
    window.open('/target-doc')
  }
    return(
      <div>
        <div className="header" style={styles.header}>
          <div onClick={() => navigate('/')} style={styles.logoRow} className="logo-row">
            <img style={styles.logo} src={duckLogo}/>
            <div className="logo">Duck Duck Oh</div>
          </div>
          <div className="search-form">
            <input value={searchQ} onChange={(e)=> setSearchQ(e.target.value)} style={styles.input} type="text" placeholder="Search"/>
            <button type="submit" onClick={search}>Search</button>
          </div>
        </div>
        <div style={{display: "flex", gap: 20,paddingLeft: "20px", marginTop: 30, borderBottom: "1px solid #d6d6d6", paddingBottom: 10}}>
          <span onClick={()=> setCurrentTab("documents")} style={{cursor: "pointer", fontWeight: currentTab=="documents"? "bold": 500, color: currentTab=="documents"? "#e28743": "grey" }}>Documents</span>
          <span onClick={()=> setCurrentTab("images")} style={{fontWeight: currentTab=="images"? "bold": 500, color: currentTab=="images"? "#e28743": "grey" , cursor: "pointer"}}>Images</span></div>
        <div className="content" style={styles.results}>
          {
                currentTab == "documents"?               
                  ( results.length !== 0 ?
                      renderedResults : <span>There are no available documents for {query}.</span>)
                : (images.length !== 0 ?
                    <div style={{display:"flex", flexDirection: "row", gap: 15, flexWrap: "wrap"}}>
                      {renderedImages}
                    </div> : <span>There are no available images for {query}.</span>)
                  
          }
        </div>
      </div>
    )
}

const styles= {
  header:{
    position: "sticky",
  },
  logo: {
    width: "30px",
    marginLeft: "20px",
  },
  input:{
    borderRadius: "5px",
  },
  logoRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  results:{
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
  },
  title:{
    fontSize: "18px",
    fontWeight: "bold",
    cursor: "pointer",
    color:"#154c79"
  },
  body :{
    //whiteSpace: "nowrap",
    //overflow: "hidden",
    //textOverflow: "ellipsis",
    width: "40vw",
    display: "inline-block"
  }
  
};