import axios from "axios";

export const api = axios.create({
    baseURL: 'https://duck-duck-oh.herokuapp.com',
    withCredentials: true,
    headers: {
        common: {
            Accept: "application/json",
            "Content-type": "application/json",
        },

    },
    mode: "no-cors"
});